import {Tab} from '@headlessui/react';
import classNames from 'classnames';

import {Jobs} from '../helper/jobs';
import {ReferenceItem} from './ReferenceItem';
import {TabWrapper} from '../assets/styles/Sidebar.styles';

export const Sidebar = () => {
	const resetScroll = () => window.scrollTo(0, 0);

	return (
		<TabWrapper className='Tab-wrapper'>
			<Tab.Group vertical>
				<Tab.List className='tab-list'>
					<nav>
						{Jobs.map((job, i) => {
							return (
								<Tab
									className={({selected}) =>
										classNames('tab-button font-bold', selected ? 'active' : '')
									}
									key={job.id}
									onClick={resetScroll}
								>
									{job.title}
								</Tab>
							);
						})}
					</nav>
				</Tab.List>

				<Tab.Panels className='tab-panels'>
					{Jobs.map((job) => {
						return (
							<Tab.Panel key={job.id} className='tab-panel'>
								<div key={job.id}>
									<h3>{job.title}</h3>
									<p>{job.description}</p>
									<div className='ref-box-wrapper'>
										{job.portfolio.map((portfolio, idx) => {
											return (
												<ReferenceItem
													key={idx}
													year={portfolio.year}
													location={portfolio.location}
													name={portfolio.name}
													job={portfolio.job}
													description={portfolio.description}
													details={portfolio.details}
													link={portfolio.id}
												/>
											);
										})}
									</div>
								</div>
							</Tab.Panel>
						);
					})}
				</Tab.Panels>

				{/* <Tab.Panels className='tab-panels'>
					{Jobs.map((job, i) => {
						return (
							<Tab.Panel key={job.id} className='tab-panel'>
								{job.menuSubItem.map((menuSubItem, idx) => {
									return (
										<div key={idx}>
											<h3>{menuSubItem.title}</h3>
											<p>{menuSubItem.description}</p>
											<div className='ref-box-wrapper'>
												{menuSubItem.portfolio?.map((portfolio, idx) => {
													return (
														<ReferenceItem
															key={idx}
															year={portfolio.year}
															location={portfolio.location}
															name={portfolio.name}
															job={portfolio.job}
															description={portfolio.description}
															details={portfolio.details}
															link={portfolio.id}
														/>
													);
												})}
											</div>
										</div>
									);
								})}
							</Tab.Panel>
						);
					})}
				</Tab.Panels> */}

				{/* <Tab.Panels className='tab-panels'>
					{Jobs &&
						Jobs.map((content, i) => {
							return (
								<Tab.Panel key={i} className='tab-panel'>
									<p>{content.text}</p>
									<div className='ref-box-wrapper'>
										{content.portfolio?.map((y, i) => {
											return (
												<ReferenceItem
													key={y.id}
													year={y.year}
													location={y.location}
													name={y.name}
													job={y.job}
													description={y.description}
													details={y.details}
													link={y.id}
												/>
											);
										})}
									</div>
								</Tab.Panel>
							);
						})}
				</Tab.Panels> */}
			</Tab.Group>
		</TabWrapper>
	);
};
