import {Tab} from '@headlessui/react';
import classNames from 'classnames';

import {AboutMe} from '../helper/dataList';
import {TabWrapper} from '../assets/styles/Sidebar.styles';
import {AboutmeItem} from './AboutMeItem';
import {AboutmeQualification} from './AboutMeItem';
import {Contact} from './Contact';

export const AboutmeSidebar = () => {
	const resetScroll = () => window.scrollTo(0, 0);

	return (
		<>
			<TabWrapper className='Tab-wrapper'>
				<Tab.Group vertical>
					<Tab.List className='tab-list'>
						{AboutMe.map((item, i) => {
							return (
								<Tab
									className={({selected}) =>
										classNames('tab-button font-bold', selected ? 'active' : '')
									}
									key={i}
									onClick={resetScroll}
								>
									{item.title}
								</Tab>
							);
						})}
						<Contact className='mobile-h tablet-h mobileContact laptop-d' />
					</Tab.List>

					<Tab.Panels className='tab-panels'>
						{AboutMe?.map((item, i) => {
							return (
								<Tab.Panel key={i} className='tab-panel '>
									<div className='aboutme-box-wrapper'>
										{item.data?.map((d) => {
											return (
												<AboutmeItem
													key={d.id}
													year={d.year}
													company={d.company}
													position={d.position}
													location={d.location}
													school={d.school}
													dep={d.dep}
												/>
											);
										})}
										{item.qualification?.map((q) => {
											return (
												<AboutmeQualification
													key={q.id}
													title={q.title}
													description={q.description}
												/>
											);
										})}
									</div>
								</Tab.Panel>
							);
						})}
					</Tab.Panels>
				</Tab.Group>
			</TabWrapper>
		</>
	);
};
