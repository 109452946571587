import styled from 'styled-components';
import * as style from './Var.style';

export const ImgStyle = styled.img`
	object-fit: cover;
	width: 100%;
	margin-bottom: 1rem;
`;
export const RefContainer = styled.div`
	padding: 0rem 1rem 0rem 1rem;

	& .header {
		margin: 0rem 0rem 1rem 0rem;

		& .form {
			margin: 0rem 0rem 1rem 0rem;

			& h2 {
				font-weight: lighter;
				margin: 0rem 0rem 5px 0rem;
				color: ${style.gray};
			}
			& h1 {
				font-weight: lighter;
			}
			& ul {
				padding-left: 2rem;
				& li {
					list-style: disc;
					line-height: 25px;
				}
			}
		}
	}
`;
