import {HashLink} from 'react-router-hash-link';
import {useNavigate} from 'react-router-dom';
// import {
// 	FaMobileScreen,
// 	FaRegEnvelopeOpen,
// 	FaFacebook,
// 	FaLinkedin,
// } from 'react-icons/fa6';
import {
	MenuBar,
	Profile,
	MenuItem,
	// MobileContact,
} from '../assets/styles/Menu.style';
import IMG from '../helper/imgImports';
// import {Contact} from '../helper/dataList';
import {MenuToggle} from '../helper/Toggle';
import {Contact} from './Contact';

const MenuSection = () => {
	const nav = useNavigate();
	return (
		<MenuBar className='Menu-bar'>
			<Profile>
				<img
					className='profileImg '
					src={IMG.profileNew}
					alt='Profil kép'
					onClick={() => nav('/')}
				/>
				<div className='nameBox'>
					<h1 className='name'>ERDEI ADRIÁN</h1>
					<p className='nameTitle'>Okleveles építész, építőmérnök</p>
				</div>
			</Profile>

			<MenuToggle>
				<div className='laptop-h desktop-h'>
					<Contact />
				</div>

				<MenuItem className='menu-item'>
					<HashLink to='/'>PORTFOLIÓ</HashLink>
					<HashLink to='/About-me'>RÓLAM</HashLink>
				</MenuItem>
			</MenuToggle>
		</MenuBar>
	);
};

export default MenuSection;
