import {AboutmeContainer} from '../assets/styles/AboutmeDetails.style';

export const AboutmeItem = ({
	year,
	company,
	position,
	location,
	school,
	dep,
}) => {
	return (
		<AboutmeContainer className='aboutme-box'>
			<h1 className='title'>{year}</h1>
			<p className='content'>
				<span>{company || school}</span>
				<span>{position || dep}</span>
				<span>{location}</span>
			</p>
		</AboutmeContainer>
	);
};

export const AboutmeQualification = ({title, description}) => {
	return (
		<AboutmeContainer className='aboutme-box'>
			<h1 className='title'>{title}</h1>
			<ul className='content'>{description}</ul>
		</AboutmeContainer>
	);
};
