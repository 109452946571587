import React, {useState, useRef} from 'react';
import {FaPlus, FaBarsStaggered} from 'react-icons/fa6';
import {Icon, MobileMenuItem} from '../assets/styles/Menu.style';

export const MenuToggle = ({children}) => {
	const [menuToggle, setMenuToggle] = useState(false);
	const menuToggleRef = useRef();
	// if (menuToggleRef.current) console.log(menuToggleRef.current.scrollHeight);

	return (
		<>
			<Icon className='iconBox laptop-h desktop-h'>
				<FaBarsStaggered onClick={() => setMenuToggle(!menuToggle)} />
			</Icon>
			<MobileMenuItem
				className='mobile-menu-item'
				ref={menuToggleRef}
				style={
					menuToggle
						? {
								opacity: '1',
								height: menuToggleRef.current.scrollHeight + 'px',
						  }
						: {}
				}
			>
				{children}
			</MobileMenuItem>
		</>
	);
};

export const Toggle = ({children, title, subTitle}) => {
	const [toggle, setToggle] = useState(false);
	const toggleRef = useRef();

	return (
		<>
			<div className='toggle' onClick={() => setToggle(!toggle)}>
				<h1 className='title mb1'>
					{title}
					{toggle ? (
						<FaPlus
							className='faPlus'
							style={{
								transform: 'rotate(45deg)',
							}}
						/>
					) : (
						<FaPlus
							className='faPlus'
							style={{
								transform: 'rotate(0deg)',
							}}
						/>
					)}
				</h1>
				<h2 className='subTitle ml1'>{subTitle}</h2>
			</div>

			<div
				className='accordion pl1 ml1'
				ref={toggleRef}
				style={
					toggle
						? {
								height: toggleRef.current.scrollHeight + 'px',
								margin: '1rem 0 0 1rem',
						  }
						: {
								height: '0px',
						  }
				}
			>
				{children}
			</div>
		</>
	);
};
