import styled from 'styled-components';
import * as style from './Var.style';

export const AppContainer = styled.div`
	display: grid;
	gap: 1rem;
`;
export const Container = styled.div`
	display: grid;
	grid-template-columns: repeat(1, auto);
	font-size: 16px;
`;
export const Head = styled.div`
	justify-self: center;

	& h1 {
		letter-spacing: 3px;
		text-transform: uppercase;
	}
`;
export const TabWrapper = styled.div`
	display: grid;
	grid-template-rows: repeat(2, auto);
	row-gap: 1rem;

	& .tab-list {
		display: flex;
		overflow: auto;
		gap: 1rem;
		white-space: nowrap;
		padding: 1rem 0rem 1rem 0rem;
		position: sticky;
		top: 70px;
		background-color: ${style.black};
		-ms-overflow-style: none; /* IE, Edge */
		scrollbar-width: none; /* Firefox */

		& nav {
			display: flex;
			flex-direction: row;
			margin-bottom: 0.5rem;
		}

		&::-webkit-scrollbar {
			display: none; /* Chrome, Safari, Opera */
		}

		& .mobileContact {
			margin: 2rem 0rem 0rem 0rem;
		}

		& button {
			margin: 0px 15px 0px 15px;
			padding: 8px 0px 8px 0px;
			height: 40px;
			line-height: 25px;
			border: none;
			font-size: 16px;
			color: ${style.link};
			background-color: transparent;
			cursor: pointer;
			text-align: start;
			width: fit-content;
			&:focus-visible {
				outline: none;
			}
		}
		& .active {
			box-sizing: border-box;
			color: ${style.orange};
			border-bottom: 2px solid ${style.orange};
			/* transition: color 0.1s ease-in-out, border 0.2s ease-in-out;} */
		}
		& .contact-location {
			display: inline-flex;
		}
	}

	& .aboutme-flex-center {
		display: flex;
		justify-content: center;
	}

	& .tab-list {
	}

	& .tab-panels {
		padding: 0 1rem 0 1rem;

		& h3 {
			margin-top: 0rem;
			margin-bottom: 1rem;
		}

		& p {
			margin-bottom: 1rem;
		}

		& .ref-box-wrapper {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 1rem;

			& .refbox {
				background-color: ${style.darkGray};
				padding: 1rem 1rem 1rem 1rem;
				margin: 0px 0px 10px 0px;
				border-radius: 6px;

				& .head {
					margin-bottom: 1rem;

					& h3 {
						margin: 0px 0px 5px 0px;
						font-size: 16px;
					}
					& h4 {
						margin: 0px 0px 14px 0px;
						font-weight: normal;
					}
					& h5 {
						margin: 0px 0px 5px 0px;
						font-weight: normal;
						font-size: 16px;
					}
					& p {
						font-size: 14px;
						margin: auto 0;
						color: ${style.gray};
					}
				}

				& .footer {
					display: flex;
					justify-content: space-between;

					& .disabled {
						cursor: unset !important;
						/* color: ${style.gray} !important; */
						display: none !important;
					}

					& .btn {
						display: flex;
						justify-content: flex-end;
						padding: 8px 10px 8px 10px;
						margin: 0px 0px -8px -10px;
						border-radius: 6px;
						border: none;
						font-size: 14px;
						color: ${style.link};
						background-color: transparent;
						cursor: pointer;
						&:hover {
							color: ${style.orange};
						}

						& svg {
							margin: auto 0px auto 10px;
						}
					}
				}
			}
		}
	}
`;
