import {styled} from 'styled-components';
import * as style from './Var.style';

export const AboutmeContainer = styled.div`
	margin: 0 0 2rem 0;
	& .title {
		margin: 0px 0px 3px 0px;
	}

	& .content {
		display: flex;
		flex-direction: column;
		margin: 0 0 0 5px;
		padding: 12px 0px 3px 10px;
		border-left: 2px solid ${style.orange};

		& span {
			margin: 0px 0px 5px 0px;
		}

		& span:nth-child(2) {
			color: ${style.gray};
		}
	}

	& ul.content {
		margin: 0 0 0 5px;
		padding: 12px 0px 3px 10px;

		li {
			margin: 0px 0px 5px 0px;
		}
	}
`;
