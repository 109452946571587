import {useParams} from 'react-router-dom';
import {Jobs} from '../helper/jobs';
import {RefContainer, ImgStyle} from '../assets/styles/ReferenceDetail.style';

const RefItemDetails = () => {
	const {refId} = useParams();
	let portfolioItem = null;

	for (const service of Jobs) {
		if (service.portfolio && Array.isArray(service.portfolio)) {
			portfolioItem = service.portfolio.find(
				(portfolio) => portfolio.id === refId
			);
			if (portfolioItem) {
				break; // Break the loop if the portfolio item is found
			}
		}
	}

	return (
		<RefContainer key={portfolioItem.id} className='ref-container'>
			<div className='header'>
				<div className='form'>
					<h2>Tervezés éve:</h2>
					<h1>{portfolioItem.year}</h1>
				</div>
				<div className='form'>
					<h2>Projekt</h2>
					<h1>{portfolioItem.name}</h1>
				</div>
				<div className='form'>
					<h2>Szolgáltatás</h2>
					<h1>{portfolioItem.job}</h1>
				</div>
				<div className='form'>
					<p>{portfolioItem.description}</p>
				</div>
			</div>
			<div className='img-wrapper'>
				{portfolioItem.imgCollection.map((img, idx) => {
					return (
						<div key={idx}>
							<ImgStyle src={img} alt='' />
						</div>
					);
				})}
			</div>
		</RefContainer>
	);
};

export default RefItemDetails;
