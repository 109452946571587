import {BrowserRouter, Routes, Route} from 'react-router-dom';

//Import Global style
import {GlobalStyle} from './assets/styles/globalStyles.style';
import {GlobalMediaStyle} from './assets/styles/globalMedia.style';
import {AppContainer} from './assets/styles/Sidebar.styles';

//import Pagess
import RefItemDetails from './pages/ReferenceDetail';
import Error from './pages/Error';
import HomeSidebar from './pages/Home';
import Aboutme from './pages/Aboutme';
import MenuSection from './components/Menu';
import ScrollToTop from './helper/ScrollToTop';

function App() {
	return (
		<AppContainer className='App-Container'>
			<BrowserRouter>
				<GlobalMediaStyle />
				<GlobalStyle />
				<MenuSection />
				<ScrollToTop />
				<Routes>
					<Route path='/' element={<HomeSidebar />} />
					<Route path='/referencia/:refId' element={<RefItemDetails />} />
					<Route path='*' element={<Error />} />
					<Route path='/About-me' element={<Aboutme />} />
				</Routes>
			</BrowserRouter>
		</AppContainer>
	);
}

export default App;
