import IMG from './imgImports';

export const Jobs = [
	// {
	// 	id: 'job01',
	// 	title: 'Építészeti tervezés',
	// 	description: [
	// 		'A vázlattervek készítése ingyenes az elfogadott koncepció megtervezéséig. Amennyiben nem sikerül megfelelő tervet készítem, nem kérek tervezési díjat, csak azt, hogy a terveket ne használják fel. Engedélyezési terv megegyezés szerint, alanyi adómentesen, családi lakóházak esetében  300 000,- Ft. -tól.',
	// 		<br />,
	// 		<br />,
	// 		'Véleményem szerint, a tervezés egy folyamat, melyet az építtetőnek is meg kell élnie, értelmeznie, magáévá tennie, melyhez sok - sok egyeztetés szükséges. A tervezett épületnek az építészeti illeszkedésen és esztétikán túl, elsősorban jól átgondoltnak, időtállónak, és funkcionálisan tökéletesnek kell lennie. Ez sok közös munkát kíván, és családi ház esetében is minimum 6 hét. Hiszem, hogy csak így érdemes. Jót, gyorsan, és az „ide jó lesz az” köszönöm nem érdekel.',
	// 		<br />,
	// 		<br />,
	// 		'Természetesen a folyamatos látványtervezés, részletes kivitelezést támogató kidolgozottság és dokumentáció, a belső funkcionális berendezések tervével alap, és biztosított.',
	// 	],
	// 	portfolio: [
	// 		{
	// 			id: crypto.randomUUID(),
	// 			year: '2015',
	// 			location: 'Budapest, XVIII. Kara utca',
	// 			name: 'Családiház',
	// 			job: 'Bővítés, korszerűsítése.',
	// 			description:
	// 				'Budapest XVIII. kerület, családi ház bővítése elemet ráépítéssel.  A felső lakáshoz külön lépcsőház készül, és a meglévő patinás tetőszerkezet látszó faszerkezetként megmarad, nagy belmagasságú fiatalos otthonná válik.',
	// 			imgCollection: [IMG.ref0201, IMG.ref0202, IMG.ref0203, IMG.ref0204],
	// 			details: true,
	// 		},
	// 		{
	// 			id: crypto.randomUUID(),
	// 			year: '2015',
	// 			location: 'Balatonlelle',
	// 			name: 'LIDO Hotel',
	// 			job: 'Fejlesztési koncepcióterv.',
	// 			description:
	// 				'A hotel korszerűsítése, és „négy évszakossá” tételének koncepciótervezése. Új egész évben nyitva tartó étterem és rendezvényterem. Wellness részleg és konferencia termek kialakítása, valamint új „köntös” és korszerűsítés. A tulajdonosokkal a közös tervezés és gondolkodás 2008. évben kezdődött.',
	// 			imgCollection: [IMG.ref0301],
	// 			details: true,
	// 		},
	// 		{
	// 			id: crypto.randomUUID(),
	// 			year: '2014',
	// 			location: 'Budapest, XVII. Bertóti utca',
	// 			name: 'Családiház',
	// 			job: 'Engedélyezési és kiviteli terv.',
	// 			description: [
	// 				'Budapest XVII. kerület, kertvárosi földszintes családi ház. Egyszerű épület, mégis a belső funkciók és kihasználhatóságot nagyon hosszan egyeztettük az építtetőkkel, az épület egyszerűen letisztult, és „ jól működő” lett. A végeredményt segítette a profi ',
	// 				<a href='https://e-hazak.hu' target='_blank' rel='noreferrer'>
	// 					generálkivitelező
	// 				</a>,
	// 				' csapat is.',
	// 			],
	// 			imgCollection: [],
	// 			details: true,
	// 		},

	// 		{
	// 			id: crypto.randomUUID(),
	// 			year: '2008',
	// 			location: 'Budapest, VI. Székely Mihály utca',
	// 			name: 'Lakóépület ',
	// 			job: 'Bővítés, önálló engedélyezési tervezés.',
	// 			description:
	// 				'Budapest VI. kerület. Első fővárosi tervezési munka, a Promega építésziroda alkalmazottjaként, Kolbe Tibor vezetésével.  Önálló tervezési feladat, belvárosi 3 szintes társasház emeletráépítésének (+4szint) és felújításának önálló engedélyezési tervezése.',
	// 			imgCollection: [IMG.ref0901, IMG.ref0902],
	// 			details: true,
	// 		},
	// 		{
	// 			id: crypto.randomUUID(),
	// 			year: '2007',
	// 			location: 'Zalaegerszeg',
	// 			name: 'COLOR PARK, 80 lakásos társasház',
	// 			job: 'Engedélyezési és kiviteli tervezése.',
	// 			description:
	// 				'Zalaegerszeg, Mártírok útja, volt COLOR üzemé épület átalakítása, bővítése, valamint új önálló társasház önálló engedélyezési tervezése, valamint építészeti kiviteli tervezése, és szakági koordinálása. A Látványterveket Bálizs Andrej készítette, felelős tervező Czigány István, Zalaart építész Iroda. ',
	// 			imgCollection: [
	// 				IMG.ref1001,
	// 				IMG.ref1002,
	// 				IMG.ref1003,
	// 				IMG.ref1004,
	// 				IMG.ref1005,
	// 				IMG.ref1006,
	// 				IMG.ref1007,
	// 				IMG.ref1008,
	// 			],
	// 			details: true,
	// 		},
	// 		{
	// 			id: crypto.randomUUID(),
	// 			year: '2006',
	// 			location: 'Balatonlelle',
	// 			name: 'Balaton parti, 22 lakásos apartman ház',
	// 			job: 'Engedélyezési és kiviteli tervezés, engedélyeztetés.',
	// 			description:
	// 				'Balatonlelle, Móló sétány. Első önálló 22 lakásos társasház. Önálló építészeti tervezés, a koncepciótervektől a megvalósulásig. Minden lakás, nagy Balatonra néző terasszal rendelkezik. Minden szinten különböző alaprajz, önálló IV. emeleti tetőterasz körpanorámával. Tervet engedélyezte, néhai Lőrinc Ferenc építész. Mentor Dabóczi József.',
	// 			imgCollection: [
	// 				IMG.ref1101,
	// 				IMG.ref1102,
	// 				IMG.ref1103,
	// 				IMG.ref1104,
	// 				IMG.ref1105,
	// 			],
	// 			details: true,
	// 		},
	// 		{
	// 			id: crypto.randomUUID(),
	// 			year: '2006',
	// 			location: 'Almásháza',
	// 			name: 'Hagyományos családi ház',
	// 			job: 'Tervezés.',
	// 			description:
	// 				'Almásháza, hagyományos családi ház építése. Az épület az első tervezésmunkák egyike. Földszintes tégla épület tornácokkal, látszó fa födémmel, de nem látszó tetőtér beépítéssel és garázsokkal. Az építtető család otthona a megépítés óta',
	// 			imgCollection: [],
	// 			details: true,
	// 		},
	// 		{
	// 			id: crypto.randomUUID(),
	// 			year: '2004',
	// 			location: 'Fonyódliget',
	// 			name: 'Hétvégi ház',
	// 			job: 'Tervezése és kivitelezése.',
	// 			description:
	// 				'Fonyódliget, Rezeda utca. Első Önálló tervezési és kivitelezési munka. A szabályozási tervek teljes vertikális és horizontális kiaknázása. Két önálló elszeparált lakás került kialakításra.',
	// 			imgCollection: [],
	// 			details: true,
	// 		},
	// 		{
	// 			id: crypto.randomUUID(),
	// 			year: '2015',
	// 			location: 'Budapest, XI. Vincellér utca',
	// 			name: 'Lakás',
	// 			job: 'Belső tervezés.',
	// 			description: '',
	// 			imgCollection: [],
	// 			details: false,
	// 		},
	// 		{
	// 			id: crypto.randomUUID(),
	// 			year: '2015',
	// 			location: 'Budapest, XI. Ulászló utca',
	// 			name: 'Társasház',
	// 			job: 'Kerítés, tárolók tervezése.',
	// 			description: '',
	// 			imgCollection: [],
	// 			details: false,
	// 		},
	// 		{
	// 			id: crypto.randomUUID(),
	// 			year: '2014',
	// 			location: 'Budapest, XIX Árpád utca',
	// 			name: 'Családiház',
	// 			job: 'Engedélyezési és kiviteli terv.',
	// 			description: '',
	// 			imgCollection: [],
	// 			details: false,
	// 		},
	// 		{
	// 			id: crypto.randomUUID(),
	// 			year: '2014',
	// 			location: 'Dunaújváros',
	// 			name: 'DunaTrade üzem',
	// 			job: 'Konyhatechnológia és belsőépítészeti tervezés.',
	// 			description: '',
	// 			imgCollection: [],
	// 			details: false,
	// 		},
	// 		{
	// 			id: crypto.randomUUID(),
	// 			year: '2014',
	// 			location: 'Bénye',
	// 			name: 'Családiház',
	// 			job: 'Újjáépítés tervezése.',
	// 			description: '',
	// 			imgCollection: [],
	// 			details: false,
	// 		},
	// 		{
	// 			id: crypto.randomUUID(),
	// 			year: '2014',
	// 			location: 'Budapest XIX.',
	// 			name: 'Családiház VK30',
	// 			job: 'Tervezés.',
	// 			description: '',
	// 			imgCollection: [],
	// 			details: false,
	// 		},
	// 		{
	// 			id: crypto.randomUUID(),
	// 			year: '2014',
	// 			location: 'Gomba',
	// 			name: 'Családiház',
	// 			job: 'Tervezés, bővítés.',
	// 			description: '',
	// 			imgCollection: [],
	// 			details: false,
	// 		},
	// 		{
	// 			id: crypto.randomUUID(),
	// 			year: '2012',
	// 			location: 'Zalaegerszeg',
	// 			name: 'Családiház F16',
	// 			job: 'Koncepcióterv.',
	// 			description: '',
	// 			imgCollection: [],
	// 			details: false,
	// 		},
	// 		{
	// 			id: crypto.randomUUID(),
	// 			year: '2012',
	// 			location: 'Budapest',
	// 			name: 'Családiház J206',
	// 			job: 'Tervezés.',
	// 			description: '',
	// 			imgCollection: [],
	// 			details: false,
	// 		},
	// 		{
	// 			id: crypto.randomUUID(),
	// 			year: '2011',
	// 			location: 'Fonyódliget',
	// 			name: 'Apartman házak',
	// 			job: 'Beépítési tervek.',
	// 			description: '',
	// 			imgCollection: [],
	// 			details: false,
	// 		},
	// 		{
	// 			id: crypto.randomUUID(),
	// 			year: '2011',
	// 			location: 'Budapest, II. Vitéz utca',
	// 			name: 'Tetőtéri p.h.',
	// 			job: 'Elvi engedélyezés.',
	// 			description: '',
	// 			imgCollection: [],
	// 			details: false,
	// 		},
	// 		{
	// 			id: crypto.randomUUID(),
	// 			year: '2012',
	// 			location: 'Balatonlelle',
	// 			name: 'Rozmaring apartman ház',
	// 			job: 'Tervezés.',
	// 			description: '',
	// 			imgCollection: [],
	// 			details: false,
	// 		},
	// 		{
	// 			id: crypto.randomUUID(),
	// 			year: '2011',
	// 			location: 'Budapest XIX',
	// 			name: 'Saját lakóház',
	// 			job: 'Bővítés, tervezés és kivitelezés.',
	// 			description: '',
	// 			imgCollection: [],
	// 			details: false,
	// 		},
	// 		{
	// 			id: crypto.randomUUID(),
	// 			year: '2011',
	// 			location: 'Fonyódliget',
	// 			name: 'Hétvégi ház',
	// 			job: 'Engedélyterv.',
	// 			description: '',
	// 			imgCollection: [],
	// 			details: false,
	// 		},
	// 		{
	// 			id: crypto.randomUUID(),
	// 			year: '2010',
	// 			location: 'Balatonlelle',
	// 			name: 'Grill étter em',
	// 			job: 'Tervezés.',
	// 			description: '',
	// 			imgCollection: [],
	// 			details: false,
	// 		},
	// 		{
	// 			id: crypto.randomUUID(),
	// 			year: '2010',
	// 			location: 'Balatonlelle',
	// 			name: 'Móló Sétány',
	// 			job: 'fejlesztési koncepciótervek (100 lakás).',
	// 			description: '',
	// 			imgCollection: [],
	// 			details: false,
	// 		},
	// 		{
	// 			id: crypto.randomUUID(),
	// 			year: '2010',
	// 			location: 'Fonyóliget',
	// 			name: '4x4 lakásos apartam házak',
	// 			job: 'Tervezés.',
	// 			description: '',
	// 			imgCollection: [],
	// 			details: false,
	// 		},
	// 		{
	// 			id: crypto.randomUUID(),
	// 			year: '2010',
	// 			location: 'Budapest, Kispest',
	// 			name: 'E.V. Templom',
	// 			job: 'Előtető tervezés.',
	// 			description: '',
	// 			imgCollection: [],
	// 			details: false,
	// 		},
	// 		{
	// 			id: crypto.randomUUID(),
	// 			year: '2008',
	// 			location: 'Zalaegerszeg',
	// 			name: '8 lakásos társasház',
	// 			job: 'Elvi engedélyezési tervezés.',
	// 			description: '',
	// 			imgCollection: [],
	// 			details: false,
	// 		},

	// 		{
	// 			id: crypto.randomUUID(),
	// 			year: '2008',
	// 			location: 'Kaposvár',
	// 			name: 'Családiház',
	// 			job: 'Pályázati koncepcióterv.',
	// 			description: '',
	// 			imgCollection: [],
	// 			details: false,
	// 		},
	// 		{
	// 			id: crypto.randomUUID(),
	// 			year: '2008',
	// 			location: 'Kaposvár',
	// 			name: 'Családiház',
	// 			job: 'Tervezés.',
	// 			description: '',
	// 			imgCollection: [],
	// 			details: false,
	// 		},
	// 		{
	// 			id: crypto.randomUUID(),
	// 			year: '2007',
	// 			location: 'Kiskutas',
	// 			name: 'Lovarda',
	// 			job: 'Koncepcióterv.',
	// 			description: '',
	// 			imgCollection: [],
	// 			details: false,
	// 		},
	// 		{
	// 			id: crypto.randomUUID(),
	// 			year: '2007',
	// 			location: 'Zalacsány',
	// 			name: 'Üdülőfalú',
	// 			job: 'Beépítési- és épület tervek.',
	// 			description: '',
	// 			imgCollection: [],
	// 			details: false,
	// 		},
	// 		{
	// 			id: crypto.randomUUID(),
	// 			year: '2006',
	// 			location: 'Katafa',
	// 			name: 'Hagyományo családiház',
	// 			job: 'Tervezés.',
	// 			description: '',
	// 			imgCollection: [],
	// 			details: false,
	// 		},
	// 		{
	// 			id: crypto.randomUUID(),
	// 			year: '2006',
	// 			location: 'Fonyód Hegyoldal',
	// 			name: 'Új családiház',
	// 			job: 'Tervezés.',
	// 			description: '',
	// 			imgCollection: [],
	// 			details: false,
	// 		},
	// 		{
	// 			id: crypto.randomUUID(),
	// 			year: '2006',
	// 			location: 'Lengyeltóti',
	// 			name: 'Kék Hotel',
	// 			job: 'Bővítés, elvi engedélyezés.',
	// 			description: '',
	// 			imgCollection: [],
	// 			details: false,
	// 		},
	// 		{
	// 			id: crypto.randomUUID(),
	// 			year: '2005',
	// 			location: 'Fonyódliget',
	// 			name: 'Hétvégiház',
	// 			job: 'Átalakítás, bővítés tervezése.',
	// 			description: '',
	// 			imgCollection: [],
	// 			details: false,
	// 		},
	// 	],
	// },

	{
		id: 'job02',
		title: 'Generál kivitelezés',
		description: '',
		portfolio: [
			{
				id: crypto.randomUUID(),
				year: '2013 - 2014',
				location: '',
				name: 'Környezetvédelmi és Hulladékgazdálkodási projektek',
				job: 'Projektmenedzser',
				description: [
					<ul>
						<li>Som Hulladéklerakó építése</li>
						<li>Mindszent Ivóvízminőség javítás</li>
						<li>Kecel, Csatornázás III. ütem</li>
					</ul>,
				],
				imgCollection: [],
				details: true,
			},
			{
				id: crypto.randomUUID(),
				year: '2012 - 2014',
				location: '',
				name: 'Intermodális csomópontok kialakítása',
				job: 'Tanulmánytervezése',
				description: [
					<ul>
						<li>Kaposvár</li>
						<li>Pécs</li>
						<li>Szombathely</li>
					</ul>,
				],
				imgCollection: [],
				details: true,
			},
			{
				id: crypto.randomUUID(),
				year: '2012 - 2014',
				location: '',
				name: 'GYSEV vasúttársaság',
				job: 'fejlesztések tervező és előkészítő konzorcium, projektigazgató',
				description: [
					'GYSEV vasúttársaság, közlekedési operatív program keretein belül,  (KÖZOP) - azok fejlesztéseit tervező és előkészítő konzorcium, projektigazgató.',
					<ul>
						<li>
							8 - as számú vasútvonal, Győr - Fertőszentmiklós - Sopron -
							Országhatár között, kétvágányúsítás tanulmány és engedélyezési
							tervezése
						</li>
						<li>16., 17. számú vasútvonalat fejlesztési tanulmánytervezése</li>
						<li>117 es vonal felülvillamosítás</li>
						<li>Motorvonat beszerzések</li>
						<li>Kormányablakok kialakítása</li>
						<li>Zalaszentiváni Delta kialakítása</li>
						<li>Közlekedésbiztonság I., II.,</li>
						<li>Sopron állomás fejlesztése, IMCS előkészítése</li>
					</ul>,
				],
				imgCollection: [],
				details: true,
			},
			{
				id: crypto.randomUUID(),
				cover: IMG.noImg,
				year: '2011 - 2012',
				location: '',
				name: 'V0 Vasúti elkerülő',
				job: 'Tanulmánytervezés, Projektmenedzser',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2011',
				location: 'Budapest',
				name: 'Rumbach Center irodaház',
				job: 'Belső bérlemények tervezése, koordinálása',
				description:
					'Budapest VII. kerület, Király - Rumbach sarok. A Raiffeisen Evolution Kft. projektmenedzsereként, Rudolf Riedl irányítása alatt. Saját irodaház bérleményterületeinek marketing tervezése, majd szerződéskötés után önálló kiviteli tervezése, művezetése. A szakági és tűzvédelmi kialakítás tervezéskoordinációja, építészeti tervezés. Összesen kb. 10.000 nm területen. BKK irodaterületek bérlői szerződés terveinek készítése',
				imgCollection: [IMG.ref0601, IMG.ref0602],
				details: true,
			},
			{
				id: crypto.randomUUID(),
				year: '2009 - 2010',
				location: 'Budapest',
				name: 'Óbuda - Irodaház és Hotel',
				job: 'Fejlesztés, koordináció',
				description:
					'Budapest III. kerület, Lajos utca, Hilton Garden Inn esetleges Magyar fejlesztési belépési pontjainak egyike. Ingatlanfejlesztőként, ajánlati terveket készítettem a Raiffeisen Evolution csapattagjaként. Első feladatként meg kellett határozni a tervezési igényeket, fejlesztői / marketing szemmel. Az általam készített egységes tervezési kiírással, megbíztam a 3H, Fazakas, Schön, Turányi  építész irodákat, és az Építészstúdiót. Izgalmas volt nálamnál klasszisokkal, jobb építészekkel dolgozni. Kivitelezés, finanszírozás, fejlesztői és marketingelemzések és tűzvédelem fontos fejlesztői feladatok voltak a tervkészítés mentén. Minden munkát végig koordináltam, és a végén az összes terv felhasználásával részletes műszaki összehasonlítást végeztem. A legjobb tervből műszaki költségbecslés készült, saját referenciaadatokkal, saját irodaházak kiépítéseinek mutatóiból. Részletes építéstechnológiai tervezés készült, mely alapján a projekt ütemterv és költségvetési időterv készült.',
				imgCollection: [IMG.ref0701, IMG.ref0701, IMG.ref0703],
				details: true,
			},
			{
				id: crypto.randomUUID(),
				year: '2009',
				location: 'Budapest',
				name: 'Raiffeisen Evolution Hungary, s ',
				job: 'Saját irodaterület kialakítása, tervezés, koordináció és kivitelezési logisztika',
				description:
					'Budapest II. kerület Tölgyfa utca, irodaház tetőtéri bérlemény, saját iroda kialakítása. A Raiffeisen Evolution csapattagjaként saját irodaterületünk kiépítése. Belső igényfelmérés és tervezési irányelvek meghatározása után, az alap kiépítési tervek készítése. Belsőépítész Törőcsik Ferenc (Platinium Group), építészeti és kivitelezési koordináció.  1100 nm első osztályú irodaterület kialakítása A++ szinten.',
				imgCollection: [],
				details: true,
			},

			{
				id: crypto.randomUUID(),
				year: '2023',
				location: 'Kistétény',
				name: 'Lakóépület',
				job: 'Irodaépületből, négy lakásos lakóépület kialakítása.',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2023',
				location: 'Wekerletelep Gellért utca 12./1.',
				name: 'Lakóépület',
				job: 'Műemléki környezetben lévő lakóépület teljes belső átépítése.',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2023',
				location: 'Tinnye, Honfoglalás utca 44.',
				name: 'Családi ház',
				job: 'Családi ház építése.',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2023',
				location: 'Gárdony, Csuka utca 10.',
				name: 'Nyaralóépület',
				job: 'Nyaralóépület teljes felújítása.',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2023',
				location: 'Budapest XIX. Jókai utca 201.',
				name: 'Családi ház',
				job: 'Családi ház komplett energetikai korszerűsítése utólagos vízszigetelése.',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2022',
				location: 'Wekerletelep Taksony utca 7.',
				name: 'Garázsépület',
				job: 'Műemléki környezetben lévő garázsépület építése',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2022',
				location: 'Wekerletelep Taksony utca 18.',
				name: 'Lakóház',
				job: 'Műemléki környezetben lévő lakóház külső hőszigetelés.',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2022',
				location: 'Wekerletelep Kábel utca 9.',
				name: 'Lakóház',
				job: 'Műemléki környezetben lévő lakóház teljes belső átalakítás, padlófűtés, padlástér beépítés.',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2022',
				location: 'Szigetszentmiklós',
				name: 'Forgács Csempeház',
				job: 'Forgács Csempeház új csarnoképület belső munkálatok.',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2022',
				location: 'Kaprun, Ausztria',
				name: 'Panzióépület',
				job: 'Panzióépület belső felújítása.',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2022',
				location: 'Budapest, XIV. Mogyoródi u. 64.',
				name: 'Lakás',
				job: 'Lakásfelújítás',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2021',
				location: 'Wekerletelep Gomb utca 5.',
				name: 'Lakóház',
				job: 'Műemléki környezetben lévő lakóház teljes belső átalakítás, padlástér beépítés.',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2021',
				location: 'Budapest, XII. Galántai utca 12.',
				name: 'Lakás',
				job: 'Lakás teljes belső átalakítás, aljzatcsere',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2021',
				location: 'Budapest XIX. Zalaegerszeg utca 123., 125.',
				name: 'Családi házkás',
				job: 'Családi ház hőszigetelése, homlokzatfelújítása',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2020',
				location: 'Wekerletelep',
				name: 'Boldog Föld',
				job: 'Boldog Föld, csomagolásmentes bolt kialakítása.',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2020',
				location: 'Wekerletelep Hungária utca 4.',
				name: 'Lakás',
				job: 'Műemléki környezetben lévő lakásfelújítás.',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2020',
				location: 'Budaörs',
				name: 'Sorház',
				job: 'Sorház hőszigetelése',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2019',
				location: 'Budapest, Kispest Zalaegerszeg utca 148.',
				name: 'Társasház',
				job: 'Kétlakásos társasház fejlesztése',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2018',
				location: 'Budapest, XI. Őrmező, Igmándi utca',
				name: 'Lakás',
				job: 'Panellakás komplex felújítása',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2018',
				location: 'Budapest, XI. Villányi utca 86.',
				name: 'Lakás',
				job: 'Lakás komplex átépítése, felújítása',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2018',
				location: 'Budapest, Kispest Mészáros Lőrinc utca 114.',
				name: 'Társasház',
				job: 'Kétlakásos társasház fejlesztése',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2017',
				location: 'Budapest, Kispest Zalaegerszeg utca 78.',
				name: 'Családi ház',
				job: 'Családi ház építése',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2017',
				location: 'Budapest, Kispest Áchim András utca 8.',
				name: 'Családi ház',
				job: 'Családi ház építése',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2016',
				location: 'Körmend',
				name: 'Családi ház',
				job: 'Családi ház bővítés',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2016',
				location: 'Budapest, Kispest Jáhn Ferenc utca 245.',
				name: 'Családi ház',
				job: 'Családi ház építése',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2015',
				location: 'Budapest, Kispest Zalaegerszeg utca 142.',
				name: 'Társasház',
				job: 'Kétlakásos társasház fejlesztése',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2012',
				location: 'Belgrád',
				name: 'Hotel',
				job: 'Hotelfejlesztés, fejlesztési koncepció terv és számítás.',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2011',
				location: 'Törökbálint',
				name: 'Atlanta Center',
				job: 'Ingatlanfejlesztési koncepciók és gazdasági számítások.',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2011',
				location: 'Budapest, Kispest Jókai utca 222.,',
				name: 'Családi ház',
				job: 'Családi ház bővítés, emeletráépítés, korszerűsítés.',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2009',
				location: 'Budafok',
				name: 'Volt BVM üzem',
				job: 'Beépítés, fejlesztési tervek, számítások.',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2009',
				location: 'Budapest, Bacsó Béla u.',
				name: 'Társasház',
				job: '100 lakásos társasház fejlesztési tervezés és számítás.',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2008',
				location: 'Omszk Oroszország',
				name: 'Városközpont',
				job: 'Városközpont fejlesztési tervezése.',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2008',
				location: 'Balatonlelle',
				name: 'LIDO Hotel',
				job: 'bővítés, fejlesztési tervek, és elvi engedélyezés',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2002',
				location: 'Wekerletelep Pannónia utca 32.',
				name: 'Boltépület',
				job: 'Műemléki környezetben lévő boltépület teljes kiépítése.',
				description: '',
				imgCollection: [],
				details: false,
			},
		],
	},

	{
		id: 'job03',
		title: 'Generál tervezés',
		description: '',
		portfolio: [
			{
				id: crypto.randomUUID(),
				year: '2024',
				location: 'Göd, Duna part',
				name: 'Vízvédelmi Mentési Központ',
				job: 'Vízvédelmi Mentési Központ, tervezés alatt.',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2024',
				location: 'Dömsöd',
				name: 'Strandépület',
				job: 'Strandépület kiviteli tervezése.',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2023 - 2024',
				location: 'Balatonlelle',
				name: 'Hüttl Villa',
				job: 'Helyi védett épület teljes körű újragondolása, felújítás, bővítés.',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2023',
				location: 'Balatonlelle',
				name: 'Loliense társasház',
				job: 'Loliense társasház tervezése, kiviteli tervezés.',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2023',
				location: 'Balatonlelle',
				name: 'Turisztikai Központ',
				job: 'Turisztikai Központ beépítésének tervezése.',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2023',
				location: 'Ráckeve',
				name: 'Turisztikai helyszínek',
				job: 'Duna mentén turisztikai fejlesztések hat helyszínen.',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2022',
				location: 'Budapest XIX. Wekerletelep, Dobó Katica utca 46',
				name: 'Lakás',
				job: 'Műemléki környezetben tetőtérbeépítés, és lakás belső átalakítás tervezése.',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2022',
				location: 'Karácsond',
				name: 'Közösségi és Szociális épület',
				job: 'Közösségi és Szociális épület tervezése.',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2022',
				location: 'Balatonberény',
				name: 'Családi ház',
				job: 'Családi ház bővítése.',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2022',
				location: 'Ercsi',
				name: 'Ercsi Húsüzem ',
				job: 'Ercsi Húsüzem tervezése.',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2022',
				location: 'München, Németország',
				name: 'Magyar Nagykövetség',
				job: ' Magyar Nagykövetség kerítés tervezése, védett környezetben.',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2021',
				location: 'Budapest, Kispest',
				name: 'Társasház',
				job: ' 4 lakásos társasház tervezése.',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2021',
				location: 'Dunaújváros',
				name: 'T.com',
				job: 'T.Com technikai épület tervezése.',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2021',
				location: 'Budapest XIX. Wekerletelep, Gomb utca 5.',
				name: 'Lakás',
				job: 'Műemléki környezetben tetőtérbeépítés, és lakás belső átalakítás tervezése.',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2021',
				location: 'Bukarest, Románia',
				name: 'Magyar Nagykövetség',
				job: 'Magyar Nagykövetség épületének fűtéskorszerűsítése.',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2021',
				location: ' Budapest XIX. Jókai utca 137.',
				name: 'Lakóépület',
				job: 'Három lakásos lakóépület tervezése.',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2020',
				location: 'Pécs',
				name: 'T.Com',
				job: 'T.Com irodaépület belső kialakítása.',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2019',
				location: 'Innsbruck Ausztria',
				name: 'Magyar Főkonzulátus',
				job: 'Magyar Főkonzulátus iroda tervezése, és belsőépítészeti kialakítása.',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2019',
				location: 'Vilnius Litvánia',
				name: 'Magyar Főkonzulátus',
				job: 'Magyar Főkonzulátus iroda tervezése, és belsőépítészeti kialakítása.',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2016',
				location: 'Balatonlelle',
				name: 'Lidó hotel',
				job: 'Lidó hotel újjáépítése, bővítése.',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2015',
				location: 'Balatonlelle',
				name: 'Móló II.',
				job: '77 lakásos társasház Generáltervezése.',
				description: '',
				imgCollection: [],
				details: false,
			},
		],
	},

	{
		id: 'job04',
		title: 'Projekt menedzsment',
		description: '',
		portfolio: [
			{
				id: crypto.randomUUID(),
				year: '2022 - 2023',
				location: 'Bátonyterenye,',
				name: 'Salgotherm üzemépület',
				job: 'Salgotherm üzemépület komplex energetikai felújítása.',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2022',
				location: 'Mikepércs,',
				name: 'Szociális Otthon',
				job: 'Szociális Otthon energetikai felújítása.',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2019',
				location: 'Innsbruck Ausztria',
				name: 'Magyar Főkonzulátus',
				job: 'Magyar Főkonzulátus tervezői felügyelete.',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2019',
				location: 'Vilnius Litvánia',
				name: 'Magyar Nagykövetség',
				job: 'Magyar Főkonzulátus tervezői felügyelete.',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2016.',
				location: 'Balatonszemes',
				name: 'Park',
				job: 'Településközponti lakópark fejlesztése.',
				description: '',
				imgCollection: [],
				details: false,
			},
		],
	},
];
