import {createGlobalStyle} from 'styled-components';
import * as style from './Var.style';
import {device as d} from './mediaQuery.style';

export const GlobalStyle = createGlobalStyle` 
  @font-face {
    font-family: 'NeueMedium';
    src: local('Neue Regrade Medium'), url('./assets/fonts/Neue Regrade Medium.otf') format('opentype');
  }
  @font-face {
    font-family: 'Neue600';
    src: local('Neue Regrade Bold'), url('./assets/fonts/Neue Regrade Bold.otf') format('opentype');
  }
  @font-face {
    font-family: 'Neue';
    src: local('Neue Regrade Medium'), url('./assets/fonts/Neue Regrade Medium.otf') format('opentype');
  }
  html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    scroll-behavior: smooth;
  }

  body {
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    color: ${style.white};
    background-color: ${style.black}
  }

  h1 {
    font-family: 'Neue600', 'Montserrat';
    font-size: 1.6rem;
    font-weight: 600;
    margin: 0;
    letter-spacing: 1px;
  }
  h2 {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0;
  }
  h4, h5 {
     margin: 0;
    }

  p {
    margin: 0;
    line-height:20px;
  }

  li {
    list-style: none;
  }

  a {
    background-color: transparent;
    color: ${style.link};
    text-decoration: none;

    &:hover {
      color:${style.orange};
    }
  }

  img {
    border-style: none;
  }

  button,
  input {
    font-family: inherit;
    line-height: 1.15;
    margin: 0;
    overflow: visible;
  }

  button,
  select {
    text-transform: none;
  }

  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
  }

  .lt-space{
    letter-spacing: 3px;
  }

  .fw300 {
    font-weight: 300;
  }

  .color-gray{
    color:${style.gray};
  }

  .mb1{
    margin-bottom: 1rem;
  }

  .ml1{
    margin-left: 1rem;
  }

  .mb3 {
    margin-bottom: 3rem;
  }

  .mt1 {
    margin-top: 1rem;
  }
  .pt1 {
    padding-top: 1rem;
  }
  .pb1 {
    padding-bottom: 1rem;
  }
  .pl1 {
    padding-left: 1rem;
  }

  .fw-300 {
    font-weight: 300;
  }
 
  //Media querys
  .desktop-h{
    @media ${d.desktop}{display: none;}
  }
  .laptop-d {
    @media ${d.laptop}{display: unset;}
  }
  .laptop-h {
    @media ${d.laptop}{display: none;}
  }
  .tablet-h {
    @media ${d.tablet}{display: none;}  
  }
  .mobile-h {
    @media ${d.mobileSmall}{display: none;}
  }


`;
