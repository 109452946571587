import noImg from '../assets/img/noImg.jpg';
import profile from '../assets/img/profilePic.jpeg';
import profileNew from '../assets/img/profilePicNew.png';

import ref0101 from '../assets/img/ref01_01.jpg';
import ref0102 from '../assets/img/ref01_02.jpg';
import ref0103 from '../assets/img/ref01_03.jpg';
import ref0104 from '../assets/img/ref01_04.jpg';
import ref0105 from '../assets/img/ref01_05.jpg';

import ref0201 from '../assets/img/ref02_01.jpg';
import ref0202 from '../assets/img/ref02_02.jpg';
import ref0203 from '../assets/img/ref02_03.jpg';
import ref0204 from '../assets/img/ref02_04.jpg';

import ref0301 from '../assets/img/ref03_01.jpg';

import ref0501 from '../assets/img/ref05_01.jpg';
import ref0502 from '../assets/img/ref05_02.jpg';
import ref0503 from '../assets/img/ref05_03.jpg';

import ref0601 from '../assets/img/ref06_01.jpg';
import ref0602 from '../assets/img/ref06_02.jpg';

import ref0701 from '../assets/img/ref07_01.jpg';
import ref0702 from '../assets/img/ref07_02.jpg';
import ref0703 from '../assets/img/ref07_03.jpg';

import ref0901 from '../assets/img/ref09_01.jpg';
import ref0902 from '../assets/img/ref09_02.jpg';

import ref1001 from '../assets/img/ref10_01.jpg';
import ref1002 from '../assets/img/ref10_02.jpg';
import ref1003 from '../assets/img/ref10_03.jpg';
import ref1004 from '../assets/img/ref10_04.jpg';
import ref1005 from '../assets/img/ref10_05.jpg';
import ref1006 from '../assets/img/ref10_06.jpg';
import ref1007 from '../assets/img/ref10_07.jpg';
import ref1008 from '../assets/img/ref10_08.jpg';

import ref1101 from '../assets/img/ref11_02.jpg';
import ref1102 from '../assets/img/ref11_02.jpg';
import ref1103 from '../assets/img/ref11_03.jpg';
import ref1104 from '../assets/img/ref11_04.jpg';
import ref1105 from '../assets/img/ref11_05.jpg';

const IMG = {
	noImg,
	profile,
	profileNew,
	//ref01
	ref0101,
	ref0102,
	ref0103,
	ref0104,
	ref0105,
	//ref02
	ref0201,
	ref0202,
	ref0203,
	ref0204,
	//ref03
	ref0301,
	//ref05
	ref0501,
	ref0502,
	ref0503,
	//ref06
	ref0601,
	ref0602,
	//ref07
	ref0701,
	ref0702,
	ref0703,
	//ref09
	ref0901,
	ref0902,
	//ref10
	ref1001,
	ref1002,
	ref1003,
	ref1004,
	ref1005,
	ref1006,
	ref1007,
	ref1008,
	//ref11
	ref1101,
	ref1102,
	ref1103,
	ref1104,
	ref1105,
};

export default IMG;

// {IMG.ref0101}
