import {
	FaMobileScreen,
	FaRegEnvelopeOpen,
	FaLocationDot,
} from 'react-icons/fa6';
import {MobileContact} from '../assets/styles/Menu.style';
import {ContactData} from '../helper/dataList';

export const Contact = ({className}) => {
	return (
		<MobileContact className={className}>
			{ContactData.map((c, idx) => {
				return (
					<div key={idx}>
						<p>
							<FaMobileScreen />
							<a href={`tel:${c.phone}`}>{c.phone}</a>
						</p>
						<p>
							<FaRegEnvelopeOpen />
							<a href={`mailto:${c.email}`}>{c.email}</a>
						</p>
						<p>
							<FaLocationDot />
							{/* <p className='contact-location'>{c.location}</p> */}
							<a
								className='contact-location'
								href='https://maps.app.goo.gl/fiop7u9eXupoYX457'
								target='_blank'
								rel='noreferrer'
							>
								{c.location}
							</a>
						</p>
					</div>
				);
			})}
		</MobileContact>
	);
};
