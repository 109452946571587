import {Sidebar} from '../components/Sidebar';
import {Container, Head} from '../assets/styles/Sidebar.styles';

function HomeSidebar() {
	return (
		<Container className='Container' $gridArea='row-one'>
			<Head className='Head' $gridSidebarArea='content'>
				<h1>portfolió</h1>
			</Head>
			<Sidebar />
		</Container>
	);
}

export default HomeSidebar;
