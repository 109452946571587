import {createGlobalStyle} from 'styled-components';
import {MenuBar, MenuItem, MobileContact, MobileMenuItem} from './Menu.style';
import {device as d} from './mediaQuery.style';
import {Body, Container, Header, Wrapper} from './Home.style';

// import {Wrapper} from '../../pages/Home';
import * as style from './Var.style';

export const GlobalMediaStyle = createGlobalStyle`
	 @media ${d.mobileSmall}{
	}
	@media ${d.mobile}{
	}
	@media ${d.tablet}{
	} 
	 @media ${d.mobileSmall}, ${d.mobile}, ${d.tablet} {
		${Wrapper}{
			padding: 0;
		}
		${MenuBar}{
			grid-template-columns: repeat(1, auto);
			grid-template-rows: repeat(2, auto);
			align-items: self-start;
			margin-bottom: 0;

			${MobileMenuItem} {
				height: 0px;
				overflow: hidden;
				transition: height .2s ease-out;
			}

			${MobileContact}{
			margin: 1rem 0rem 2rem 0rem;
			}
		
			${MenuItem}{
				grid-area: 3/1/4/2;
				flex-direction: column;
				align-items: center;
				a {
					margin-bottom: 1rem;
				}
			}
		}

		${Container}{
			grid-template-columns: repeat(1, 1fr);
			grid-template-rows: repeat(2, auto);
			grid-template-areas : 'title' 'content';
			padding: 0;

			${Header}{
				position: inherit;
				margin-bottom: 0;
				& h1 {
					margin-bottom: 0;
				}
			}

			${Body}{
				padding-right: 0;
				margin: 0 1rem;
			}
		}
	}
	@media ${d.laptop}{
		.mobile-h, .tablet-h {
			display: unset !important;
		}
		.Container {
			row-gap: 2rem;

			& .Head {
				display: grid;
				grid-template-columns: repeat(10, 10%);
				grid-template-areas: 
				'. title title . . . . . . .';
				justify-self: unset;
				position: sticky;
				top: calc(71px + 1rem);

				& h1 {
					grid-area: title;
				}
			}

			& .Tab-wrapper {
				grid-template-columns: repeat(10, 10%);
				grid-template-areas: 
				'. sidebar sidebar content content content content content content .';

				& .tab-list {
					grid-area: sidebar;
					flex-direction: column;
					align-items: flex-start;
					padding: 0rem 0rem 0rem 0rem;
					height: calc(100vh - 147.84px);
					position: sticky;
					top: 147.84px;

					& nav {
						flex-direction: column;
					}
					
					& .tab-button {
						padding: 13px 0px 3px 0px;
						margin: 0rem 0rem 0rem 0rem;
						&:hover {
							color: ${style.link}
						}
					}
				}

				& .tab-panels {
					grid-area: content;
					& .tab-panel {
						display: unset;

						& .ref-box-wrapper {
							display: grid;
							grid-template-columns: repeat(2, 1fr);
							gap: 1rem;

							& .refbox {
								margin: 0;
							}
						}
					}
				}
			}
		}

		.ref-container {
			display: grid;
			grid-template-columns: repeat(10, 10%);
			grid-template-areas: 
			'. text text text img img img img img .';

			& .header {
				grid-area: text;
				overflow: auto;
				position: sticky;
				height: calc(100vh - 102.4px);
				top: 86.4px;

				& .form{

					& h1 {
						font-size: 1.35rem;
					}

					& h2 {
						font-size: 1.10rem;
					}
				}
			}

			& .img-wrapper {
				grid-area: img;
				margin-left: 2rem;
			}
		}

		${Wrapper}{
			padding: 0 5vw;

			h1 {
				font-size: 1.4rem !important;
			}
		}

		${MenuBar}{
			grid-template-columns: repeat(2, auto);
			align-items: center;
			gap: 0;

			${MenuItem}{
				flex-direction: row;

				& a {
					margin-bottom: 0rem;
				}
			}
			${MobileMenuItem}{
				height: auto;
			}
		}

		.ref-box-wrapper {
			/* display: flex; */


			& .refbox {
				/* width: 50%; */
			}
		}
	}
	@media ${d.desktop}{
		${Wrapper}{
			padding: 0 20vw;
		}
	}
`;
