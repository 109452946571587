import styled from 'styled-components';
import * as style from './Var.style';

export const MenuBar = styled.div`
	display: grid;
	/* grid-template-columns: repeat(6, 1fr); */
	grid-template-columns: repeat(2, 1fr);
	align-items: center;
	position: sticky;
	top: 0;
	z-index: 1000;
	background-color: ${style.darkGray};
	${style.border};
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
	${style.padding};
`;

export const Profile = styled.div`
	grid-area: 1 / 1 / 2/ 2;
	display: flex;

	img {
		${style.border};
		height: 35px;
		cursor: pointer;
	}
	.nameBox {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-left: 1rem;
	}
	.name {
		color: ${style.orange};
		font-size: 1rem;
	}
`;

export const MenuItem = styled.div`
	display: flex;
	justify-content: flex-end;

	a {
		font-size: 1.1rem;
		margin-right: 1.6rem;
		font-weight: lighter;
		text-decoration-line: none;

		&:hover {
			color: ${style.orange};
		}
	}
`;

export const Icon = styled.div`
	position: absolute;
	right: 1rem;
	top: 1rem;
	font-size: 2rem;
	color: ${style.link};
`;

export const MobileContact = styled.div`
	& p {
		margin-bottom: 0.5rem;
	}
	& svg {
		margin-right: 10px;
	}
`;
export const MobileMenuItem = styled.div``;
