import {useNavigate} from 'react-router-dom';
import {FaArrowRightLong} from 'react-icons/fa6';

export const ReferenceItem = ({link, location, name, job, details, year}) => {
	const navigate = useNavigate();

	return (
		<div className='refbox'>
			<div className='head'>
				<h3>{location}</h3>
				<h4>{year}</h4>
				<h5>{name}</h5>
				<p>{job}</p>
			</div>
			<div className='footer'>
				<button
					type='button btn'
					className={details === false ? 'disabled btn' : 'btn'}
					disabled={details === false}
					onClick={() => navigate('/referencia/' + link)}
				>
					Részletek
					<FaArrowRightLong />
				</button>
			</div>
		</div>
	);
};
