const size = {
	mobile: '576px',
	tablet: '576px',
	laptop: '1024px',
	desktop: '1440px',
};

export const device = {
	mobileSmall: `only screen and (max-width: ${size.mobile})`,
	mobile: `only screen and (min-width: ${size.mobile})`,
	tablet: `only screen and (min-width: ${size.tablet})`,
	laptop: `only screen and (min-width: ${size.laptop})`,
	desktop: `only screen and (min-width: ${size.desktop})`,
};
