import {Container, Head} from '../assets/styles/Sidebar.styles';
import {AboutmeSidebar} from '../components/AboutmeSidebar';

function Aboutme() {
	return (
		<Container className='Container'>
			<Head className='Head'>
				<h1>rólam</h1>
			</Head>
			<AboutmeSidebar />
		</Container>
	);
}

export default Aboutme;
