import IMG from './imgImports';

export const ContactData = [
	{
		phone: '+3630 572 80 83',
		email: 'adrianerdei@gmail.com',
		location: [
			'1034 Budapest,',
			<br />,
			'Bécsi út 100.',
			<br />,
			'LHT Irodaház',
		],
		// location: '1034 Budapest, Bécsi út 100. LHT Irodaház'
	},
];
export const ServicesList = [
	{
		id: 'services01',
		title: 'Építészeti tervezés',
		subTitle: 'Tervezés koordináció',
		subTitleList: null,
		text: [
			'A vázlattervek készítése ingyenes az elfogadott koncepció megtervezéséig. Amennyiben nem sikerül megfelelő tervet készítem, nem kérek tervezési díjat, csak azt, hogy a terveket ne használják fel. Engedélyezési terv megegyezés szerint, alanyi adómentesen, családi lakóházak esetében 300 e. Ft. -tól.',
			<br />,
			<br />,
			'Véleményem szerint, a tervezés egy folyamat, melyet az építtetőnek is meg kell élnie, értelmeznie, magáévá tennie, melyhez sok - sok egyeztetés szükséges. A tervezett épületnek az építészeti illeszkedésen és esztétikán túl, elsősorban jól átgondoltnak, időtállónak, és funkcionálisan tökéletesnek kell lennie. Ez sok közös munkát kíván, és családi ház esetében is minimum 6 hét. Hiszem, hogy csak így érdemes, jót, gyorsan, és a „ide jó lesz az”, köszönöm nem érdekel.',
			<br />,
			<br />,
			'Természetesen a folyamatos látványtervezés, részletes kivitelezést támogató kidolgozottság és dokumentáció, a belső funkcionális berendezések tervével alap, és biztosított.',
		],
		portfolio: [
			{
				id: crypto.randomUUID(),
				year: '2015',
				location: 'Budapest, XVIII. Kara utca',
				name: 'Családiház',
				job: 'Bővítés, korszerűsítése',
				description:
					'Budapest XVIII. kerület, családi ház bővítése elemet ráépítéssel.  A felső lakáshoz külön lépcsőház készül, és a meglévő patinás tetőszerkezet látszó faszerkezetként megmarad, nagy belmagasságú fiatalos otthonná válik.',
				imgCollection: [IMG.ref0201, IMG.ref0202, IMG.ref0203, IMG.ref0204],
				details: true,
			},
			{
				id: crypto.randomUUID(),
				year: '2015',
				location: 'Balatonlelle',
				name: 'LIDO Hotel',
				job: 'Fejlesztési koncepcióterv',
				description:
					'A hotel korszerűsítése, és „négy évszakossá” tételének koncepciótervezése. Új egész évben nyitva tartó étterem és rendezvényterem. Wellness részleg és konferencia termek kialakítása, valamint új „köntös” és korszerűsítés. A tulajdonosokkal a közös tervezés és gondolkodás 2008. évben kezdődött.',
				imgCollection: [IMG.ref0301],
				details: true,
			},
			{
				id: crypto.randomUUID(),
				year: '2014',
				location: 'Budapest, XVII. Bertóti utca',
				name: 'Családiház',
				job: 'Engedélyezési és kiviteli terv',
				description: [
					'Budapest XVII. kerület, kertvárosi földszintes családi ház. Egyszerű épület, mégis a belső funkciók és kihasználhatóságot nagyon hosszan egyeztettük az építtetőkkel, az épület egyszerűen letisztult, és „ jól működő” lett. A végeredményt segítette a profi ',
					<a href='https://e-hazak.hu' target='_blank' rel='noreferrer'>
						generálkivitelező
					</a>,
					' csapat is.',
				],
				imgCollection: [],
				details: true,
			},
			{
				id: crypto.randomUUID(),
				year: '2014',
				location: 'Budapest, XIX Árpád utca',
				name: 'Családiház',
				job: 'Engedélyezési és kiviteli terv',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2012',
				location: 'Balatonlelle',
				name: 'Rozmaring apartman ház',
				job: 'tervezés',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2014',
				location: 'Dunaújváros',
				name: 'Duna Trade üzem',
				job: 'konyhatechnológia és belsőépítészeti tervezés',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2011',
				location: 'Budapest XIX',
				name: 'Saját lakóház',
				job: 'bővítés, tervezés és kivitelezés',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2011',
				location: 'Fonyódliget',
				name: 'Hétvégi ház',
				job: 'engedélyterv',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2006',
				location: 'Balatonlelle',
				name: 'Balaton parti, 22 lakásos apartman ház',
				job: 'Engedélyezési és kiviteli tervezés, engedélyeztetés',
				description:
					'Balatonlelle, Móló sétány. Első önálló 22 lakásos társasház. Önálló építészeti tervezés, a koncepciótervektől a megvalósulásig. Minden lakás, nagy Balatonra néző terasszal rendelkezik. Minden szinten különböző alaprajz, önálló IV. emeleti tetőterasz körpanorámával. Tervet engedélyezte, néhai Lőrinc Ferenc építész. Mentor Dabóczi József.',
				imgCollection: [
					IMG.ref1101,
					IMG.ref1102,
					IMG.ref1103,
					IMG.ref1104,
					IMG.ref1105,
				],
				details: true,
			},
			{
				id: crypto.randomUUID(),
				year: '2010',
				location: 'Balatonlelle',
				name: 'Grill étterem',
				job: 'tervezés',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2010',
				location: 'Fonyóliget',
				name: '4x4 lakásos apartam házak',
				job: 'tervezés',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2008',
				location: 'Zalaegerszeg',
				name: '8 lakásos társasház',
				job: 'elvi engedélyezési tervezés',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2008',
				location: 'Budapest, VI. Székely Mihály utca',
				name: 'Lakóépület ',
				job: 'Bővítés, önálló engedélyezési tervezés',
				description:
					'Budapest VI. kerület. Első fővárosi tervezési munka, a Promega építésziroda alkalmazottjaként, Kolbe Tibor vezetésével.  Önálló tervezési feladat, belvárosi 3 szintes társasház emeletráépítésének (+4szint) és felújításának önálló engedélyezési tervezése.',
				imgCollection: [IMG.ref0901, IMG.ref0902],
				details: true,
			},
			{
				id: crypto.randomUUID(),
				year: '2008',
				location: 'Kaposvár',
				name: 'Családiház',
				job: 'pályázati koncepcióterv',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2007',
				location: 'Zalaegerszeg',
				name: 'COLOR PARK, 80 lakásos társasház',
				job: 'Engedélyezési és kiviteli tervezése',
				description:
					'Zalaegerszeg, Mártírok útja, volt COLOR üzemé épület átalakítása, bővítése, valamint új önálló társasház önálló engedélyezési tervezése, valamint építészeti kiviteli tervezése, és szakági koordinálása. A Látványterveket Bálizs Andrej készítette, felelős tervező Czigány István, Zalaart építész Iroda. ',
				imgCollection: [
					IMG.ref1001,
					IMG.ref1002,
					IMG.ref1003,
					IMG.ref1004,
					IMG.ref1005,
					IMG.ref1006,
					IMG.ref1007,
					IMG.ref1008,
				],
				details: true,
			},
			{
				id: crypto.randomUUID(),
				year: '2007',
				location: 'Kiskutas',
				name: 'Lovarda',
				job: 'koncepcióterv',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2006',
				location: 'Almásháza',
				name: 'Hagyományos családi ház',
				job: 'Tervezés',
				description:
					'Almásháza, hagyományos családi ház építése. Az épület az első tervezésmunkák egyike. Földszintes tégla épület tornácokkal, látszó fa födémmel, de nem látszó tetőtér beépítéssel és garázsokkal. Az építtető család otthona a megépítés óta',
				imgCollection: [],
				details: true,
			},
			{
				id: crypto.randomUUID(),
				cover: IMG.noImg,
				year: '2006',
				location: 'Balatonlelle',
				name: '22 lakásos apartman ház',
				job: 'engedélyezési és kiviteli tervezés, engedélyeztetés',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2005',
				location: 'Fonyódliget',
				name: 'Hétvégiház',
				job: 'átalakítás, bővítés tervezése',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2004',
				location: 'Fonyódliget',
				name: 'Hétvégi ház',
				job: 'Tervezése és kivitelezése',
				description:
					'Fonyódliget, Rezeda utca. Első Önálló tervezési és kivitelezési munka. A szabályozási tervek teljes vertikális és horizontális kiaknázása. Két önálló elszeparált lakás került kialakításra.',
				imgCollection: [],
				details: true,
			},
			{
				id: crypto.randomUUID(),
				year: '2006',
				location: 'Katafa',
				name: 'Családiház',
				job: 'tervezés',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2006',
				location: 'Fonyód',
				name: 'Családiház',
				job: 'tervezés',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2006',
				location: 'Lengyeltóti',
				name: 'Kék Hotel',
				job: 'bővítés, elvi engedélyezés',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2007',
				location: 'Zalacsány',
				name: 'Üdülőfal',
				job: 'beépítési- és épület tervek',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2008',
				location: 'Kaposvár',
				name: 'Családiház',
				job: 'tervezés',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2010',
				location: 'Budapest, Kispest',
				name: 'E.V. Templom',
				job: 'előtető tervezés',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2011',
				location: 'Fonyódliget',
				name: 'Apartman házak',
				job: 'beépítési tervek',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2011',
				location: 'Budapest, II. Vitéz utca',
				name: 'Tetőtéri p.h.',
				job: 'elvi engedélyezés',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2012',
				location: 'Budapest',
				name: 'Családiház J206',
				job: 'tervezés',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2012',
				location: 'Zalaegerszeg',
				name: 'Családiház F16',
				job: 'koncepcióterv',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2014',
				location: 'Bénye',
				name: 'Családiház',
				job: 'tervezés, újjáépítés',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2014',
				location: 'Budapest XIX.',
				name: 'Családiház VK30',
				job: 'tervezés',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2014',
				location: 'Gomba',
				name: 'Családiház',
				job: 'tervezés, bővítés',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2015',
				location: 'Budapest, XI. Vincellér utca',
				name: 'Lakás',
				job: 'belső tervezés',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2015',
				location: 'Budapest, XI. Ulászló utca',
				name: 'Társasház',
				job: 'kerítés, tárolók tervezése',
				description: '',
				imgCollection: [],
				details: false,
			},
		],
	}, //DONE

	{
		id: 'services02',
		title: 'Generál kivitelezés',
		subTitle: 'Ingatlanfejlesztés',
		subTitleList: [
			'EU Operatív programok',
			<br />,
			'Környezetvédelem',
			<br />,
			'Hulladékgazdálkodás',
			<br />,
			<br />,
		],
		text: [
			<span className='fw300 color-gray'>Ingatlanfejlesztés:</span>,
			<br />,
			'A tervezett ingatlan beruházást megelőző üzleti döntés-előkészítő, beépíthetőségi vizsgálatok, beépítési vázlattervek, koncepciók tervezése, komplett látványtervezés. Megvalósulás esetén tervpályázatok, felkérések lebonyolítása, tervezési koordináció, és engedélyeztetés. A műszaki tervezésen kívül a feladat része lehet, üzleti terv készítése, projektköltségvetések tervezése, készítése, saját adatbázisok alapján.',
			<br />,
			<br />,
			<span className='fw300 color-gray'>EU Operatív projektmenedzsment:</span>,
			<br />,
			'Leginkább közlekedésfejlesztési projektek kapcsán műszaki, és általános, egyéb projektek esetében általános projektmenedzsmenti feladatok ellátása. Adminisztráció, projektszervezetek felállítása, működtetése, adatbázis kezelés, folyamat menedzsment, kommunikáció szervezése, projektvezetés, referenciák alapján.',
		],
		portfolio: [
			{
				id: crypto.randomUUID(),
				year: '2023',
				location: 'Kistétény',
				name: 'Irodaépületből lakóépület',
				job: 'Irodaépületből, négy lakásos lakóépület kialakítása',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2023',
				location: 'Tinnye, Honfoglalás utca 44.',
				name: 'Családiház',
				job: 'Családi ház építése',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2023',
				location: 'Gárdony, Csuka utca 10.',
				name: 'Nyaralóépület',
				job: 'Nyaralóépület teljes felújítása,',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2022',
				location: 'Szigetszentmiklós',
				name: 'Forgács Csempeház',
				job: 'Forgács Csempeház új csarnoképület belső munkálatok',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2022',
				location: 'Ausztria, Kaprun',
				name: 'Panzióépület',
				job: 'Panzióépület belső felújítása',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2021',
				location: 'Budapest, XII. Galántai utca 12.',
				name: 'Lakás',
				job: 'Lakás teljes belső átalakítás, aljzatcsere',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2020',
				location: 'Wekerletelep,',
				name: 'Boldog Föld',
				job: 'Boldog Föld, Csomagolásmentes Bolt kialakítása',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2015',
				location: 'Székesfehérvár',
				name: 'Orvos diagnosztikai központ',
				job: 'Bővítése, korszerűsítése',
				description: [
					'Orvos diagnosztikai központ fejlesztése, bővítése, - GE BRIVOMR355 (1,5 Tesla) teljesítményű MRI és GE BRIVO DRS röntgen gépek telepítése, géptermek építése, gépészeti és energetikai felújítás, folyamatos üzemszerű működés alatt. Tervezés és előkészítés 3 hónap, kivitelezési idő 2 hónap!',
					<ul>
						<li>Beruházói képviselet</li>
						<li>Műszaki projektmenedzsment</li>
						<li>Építészeti tervezés, engedélyezés</li>
						<li>Építészeti tervezés, engedélyezés</li>
						<li>Szakági koordináció</li>
						<li>
							Gépbeszállítói, röntgentechnológiai, és kivitelezői egyeztetések
							koordinálása
						</li>
						<li>Speciális költöztetés lebonyolítása</li>
					</ul>,
				],
				imgCollection: [
					IMG.ref0101,
					IMG.ref0102,
					IMG.ref0103,
					IMG.ref0104,
					IMG.ref0105,
				],
				details: true,
			},
			{
				id: crypto.randomUUID(),
				year: '2014',
				location: 'Budapest',
				name: 'Grupama Aréna: Orvos diagnosztikai központ',
				job: 'Létrahozása, kialakítása',
				description: [
					'Az orvos diagnosztikai központ eredeti helyszíne és tervezése. Az Új Grupama arénában, a kivitelezés hajrájában tervezett 700 nm területű komplett diagnosztikai központ építészeti és belsőépítészeti tervezése. Komplett tervezéskoordináció és teljes bérlői képviselet. A megbízó egyetlen kooperáción kellett részt vennie a legelsőn, a felelős delegálását. A tervezés a teljes bútorozás, világítástechnika, asztalos részlettervek és üvegfal fólia tervek szintjén elkészült. Belsőépítész munkatárs és látványtervek készítője ',
					<a href='http://csaszarszabolcs.com' target='_blank' rel='noreferrer'>
						Császár Szabolcs.
					</a>,
					<ul>
						<li>Beruházói képviselet</li>
						<li>Műszaki projektmenedzsment</li>
						<li>
							Generál tervező koordinálása, szakági tervezések összehangolása
						</li>
						<li>Komplett belsőépítészeti tervezés</li>
						<li>Szerződések egyeztetése, előkészítése, menedzselés</li>
						<li>Röntgen engedélyezés előkészítése</li>
					</ul>,
				],
				imgCollection: [IMG.ref0501, IMG.ref0502, IMG.ref0503],
				details: true,
			},
			{
				id: crypto.randomUUID(),
				year: '2009',
				location: 'Budapest',
				name: 'Raiffeisen Evolution Hungary, s ',
				job: 'Saját irodaterület kialakítása, tervezés, koordináció és kivitelezési logisztika',
				description:
					'Budapest II. kerület Tölgyfa utca, irodaház tetőtéri bérlemény, saját iroda kialakítása. A Raiffeisen Evolution csapattagjaként saját irodaterületünk kiépítése. Belső igényfelmérés és tervezési irányelvek meghatározása után, az alap kiépítési tervek készítése. Belsőépítész Törőcsik Ferenc (Platinium Group), építészeti és kivitelezési koordináció.  1100 nm első osztályú irodaterület kialakítása A++ szinten.',
				imgCollection: [],
				details: true,
			},
			{
				id: crypto.randomUUID(),
				year: '2008',
				location: 'Budapest',
				name: 'MTV székház',
				job: 'bővítés, és új irodaház építés projektmenedzsment szervezet, junior projektmenedzser',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2011',
				location: 'Budapest',
				name: 'Rumbach Center irodaház',
				job: 'Belső bérlemények tervezése, koordinálása',
				description:
					'Budapest VII. kerület, Király - Rumbach sarok. A Raiffeisen Evolution Kft. projektmenedzsereként, Rudolf Riedl irányítása alatt. Saját irodaház bérleményterületeinek marketing tervezése, majd szerződéskötés után önálló kiviteli tervezése, művezetése. A szakági és tűzvédelmi kialakítás tervezéskoordinációja, építészeti tervezés. Összesen kb. 10.000 nm területen. BKK irodaterületek bérlői szerződés terveinek készítése',
				imgCollection: [IMG.ref0601, IMG.ref0602],
				details: true,
			},
			{
				id: crypto.randomUUID(),
				year: '2009 - 2010',
				location: 'Budapest',
				name: 'Óbuda - Irodaház és Hotel',
				job: 'Fejlesztés, koordináció',
				description:
					'Budapest III. kerület, Lajos utca, Hilton Garden Inn esetleges Magyar fejlesztési belépési pontjainak egyike. Ingatlanfejlesztőként, ajánlati terveket készítettem a Raiffeisen Evolution csapattagjaként. Első feladatként meg kellett határozni a tervezési igényeket, fejlesztői / marketing szemmel. Az általam készített egységes tervezési kiírással, megbíztam a 3H, Fazakas, Schön, Turányi  építész irodákat, és az Építészstúdiót. Izgalmas volt nálamnál klasszisokkal, jobb építészekkel dolgozni. Kivitelezés, finanszírozás, fejlesztői és marketingelemzések és tűzvédelem fontos fejlesztői feladatok voltak a tervkészítés mentén. Minden munkát végig koordináltam, és a végén az összes terv felhasználásával részletes műszaki összehasonlítást végeztem. A legjobb tervből műszaki költségbecslés készült, saját referenciaadatokkal, saját irodaházak kiépítéseinek mutatóiból. Részletes építéstechnológiai tervezés készült, mely alapján a projekt ütemterv és költségvetési időterv készült.',
				imgCollection: [IMG.ref0701, IMG.ref0701, IMG.ref0703],
				details: true,
			},
			{
				id: crypto.randomUUID(),
				year: '2009',
				location: 'Budapest',
				name: 'Raiffeisen Evolution Hungary, s ',
				job: 'Saját irodaterület kialakítása, tervezés, koordináció és kivitelezési logisztika',
				description:
					'Budapest II. kerület Tölgyfa utca, irodaház tetőtéri bérlemény, saját iroda kialakítása. A Raiffeisen Evolution csapattagjaként saját irodaterületünk kiépítése. Belső igényfelmérés és tervezési irányelvek meghatározása után, az alap kiépítési tervek készítése. Belsőépítész Törőcsik Ferenc (Platinium Group), építészeti és kivitelezési koordináció.  1100 nm első osztályú irodaterület kialakítása A++ szinten.',
				imgCollection: [],
				details: true,
			},
			{
				id: crypto.randomUUID(),
				year: '2012',
				location: 'Belgrád',
				name: 'Hotel',
				job: 'fejlesztési koncepcióterv és számítás',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2010',
				location: 'Törökbálint',
				name: 'Atlanta Center',
				job: 'Ingatlanfejlesztési koncepciók és gazdasági számítások',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2012 - 2014',
				location: null,
				name: 'GYSEV vasúttársaság',
				job: 'fejlesztések tervező és előkészítő konzorcium, projektigazgató',
				description: null,
				imgCollection: [
					'GYSEV vasúttársaság, közlekedési operatív program keretein belül,  (KÖZOP) - azok fejlesztéseit tervező és előkészítő konzorcium, projektigazgató.',
					<ul>
						<li>
							8 - as számú vasútvonal, Győr - Fertőszentmiklós - Sopron -
							Országhatár között, kétvágányúsítás tanulmány és engedélyezési
							tervezése
						</li>
						<li>16., 17. számú vasútvonalat fejlesztési tanulmánytervezése</li>
						<li>117 es vonal felülvillamosítás</li>
						<li>Motorvonat beszerzések</li>
						<li>Kormányablakok kialakítása</li>
						<li>Zalaszentiváni Delta kialakítása</li>
						<li>Közlekedésbiztonság I., II.,</li>
						<li>Sopron állomás fejlesztése, IMCS előkészítése</li>
					</ul>,
				],
				details: true,
			},
			{
				id: crypto.randomUUID(),
				year: '2009',
				location: 'Budafok',
				name: 'volt BVM üzem',
				job: 'beépítés, fejlesztési tervek, számítások',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2009',
				location: 'Budapest, Bacsó Béla utca',
				name: '100 lakásos társasház',
				job: 'fejlesztési tervezés és számítás',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2008',
				location: 'Omszk (Ru)',
				name: 'városközpont',
				job: 'fejlesztési tervezése',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2008',
				location: 'Balatonlelle',
				name: 'LIDO Hotel',
				job: 'bővítés, fejlesztési tervek, és elvi engedélyezés',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				cover: IMG.noImg,
				year: '2011 - 2012',
				location: '',
				name: 'V0 Vasúti elkerülő',
				job: 'tanulmánytervezés, projektmenedzser',
				description: '',
				imgCollection: [],
				details: false,
			},
			{
				id: crypto.randomUUID(),
				year: '2013 - 2014',
				location: '',
				name: 'Környezetvédelmi és Hulladékgazdálkodási projektek',
				job: 'projektmenedzse',
				description: [
					<ul>
						<li>Som Hulladéklerakó építése</li>
						<li>Mindszent Ivóvízminőség javítás</li>
						<li>Kecel, Csatornázás III. ütem</li>
					</ul>,
				],
				imgCollection: [],
				details: true,
			},
			{
				id: crypto.randomUUID(),
				year: '2012 - 2014',
				location: '',
				name: 'Intermodális csomópontok kialakítása',
				job: 'tanulmánytervezése',
				description: [
					<ul>
						<li>Kaposvár</li>
						<li>Pécs</li>
						<li>Szombathely</li>
					</ul>,
				],
				imgCollection: [],
				details: true,
			},
		],
	},

	{
		id: 'services03',
		title: 'Önálló megbízói képviselet',
		subTitle: 'Projekt megvalósítás',
		subTitleList: [
			'Kivitelezés koordináció',
			<br />,
			'Ingatlan értékbecslés, közvetítés',
			<br />,
			<br />,
		],
		text: [
			<span className='fw300'>Projekt megvalósítás: </span>,
			<br />,
			'Eredménycentrikus megbízás, egy adott feladat elvégzésére, behatárolt időés költségkerettel, például: Egy Diagnosztikai központlétrehozása. Helyszínkeresés, mérnöki tervezés, engedélyezések, (építés, MRI, röntgen, tűzvédelem) egyeztetések önálló bonyolítása. Tervezés koordináció, belsőépítészet, speciális költöztetés technológia (MRI és cella), szerződések menedzselése, kivitelező kiválasztás. Minden olyan folyamat, mely előre nehezen definiálható, és a megbízónak saját munkája mellett terhet jelent. Bizalmi feladat, teljes lojalitás és felelősségvállalás mellett.',
			<br />,
			<br />,
			<span className='fw-300'>Kivitelezés koordináció:</span>,
			<br />,
			'Elsősorban a saját tervezési fejlesztői munkákhoz kötődő kivitelezés felügyelet és koordináció. (Felelős Műszaki Vezető „A” kategória) Ingatlan: Bejegyzett ingatlan-közvetítő és értékbecslői végzettség. Kizárólag saját- munkák és ügyfelek kapcsán.',
		],
		portfolio: [
			{
				id: crypto.randomUUID(),
				year: '2015',
				location: 'Székesfehérvár',
				name: 'Orvos diagnosztikai központ',
				job: 'Bővítése, korszerűsítése',
				description: [
					'Orvos diagnosztikai központ fejlesztése, bővítése, - GE BRIVOMR355 (1,5 Tesla) teljesítményű MRI és GE BRIVO DRS röntgen gépek telepítése, géptermek építése, gépészeti és energetikai felújítás, folyamatos üzemszerű működés alatt. Tervezés és előkészítés 3 hónap, kivitelezési idő 2 hónap!',
					<ul>
						<li>Beruházói képviselet</li>
						<li>Műszaki projektmenedzsment</li>
						<li>Építészeti tervezés, engedélyezés</li>
						<li>Építészeti tervezés, engedélyezés</li>
						<li>Szakági koordináció</li>
						<li>
							Gépbeszállítói, röntgentechnológiai, és kivitelezői egyeztetések
							koordinálása
						</li>
						<li>Speciális költöztetés lebonyolítása</li>
					</ul>,
				],
				imgCollection: [
					IMG.ref0101,
					IMG.ref0102,
					IMG.ref0103,
					IMG.ref0104,
					IMG.ref0105,
				],
				details: true,
			},
			{
				id: crypto.randomUUID(),
				year: '2014',
				location: 'Budapest',
				name: 'Grupama Aréna: Orvos diagnosztikai központ',
				job: 'Létrahozása, kialakítása',
				description: [
					'Az orvos diagnosztikai központ eredeti helyszíne és tervezése. Az Új Grupama arénában, a kivitelezés hajrájában tervezett 700 nm területű komplett diagnosztikai központ építészeti és belsőépítészeti tervezése. Komplett tervezéskoordináció és teljes bérlői képviselet. A megbízó egyetlen kooperáción kellett részt vennie a legelsőn, a felelős delegálását. A tervezés a teljes bútorozás, világítástechnika, asztalos részlettervek és üvegfal fólia tervek szintjén elkészült. Belsőépítész munkatárs és látványtervek készítője ',
					<a href='http://csaszarszabolcs.com' target='_blank' rel='noreferrer'>
						Császár Szabolcs.
					</a>,
					<ul>
						<li>Beruházói képviselet</li>
						<li>Műszaki projektmenedzsment</li>
						<li>
							Generál tervező koordinálása, szakági tervezések összehangolása
						</li>
						<li>Komplett belsőépítészeti tervezés</li>
						<li>Szerződések egyeztetése, előkészítése, menedzselés</li>
						<li>Röntgen engedélyezés előkészítése</li>
					</ul>,
				],
				imgCollection: [IMG.ref0501, IMG.ref0502, IMG.ref0503],
				details: true,
			},
			{
				id: crypto.randomUUID(),
				year: '2009',
				location: 'Budapest',
				name: 'Raiffeisen Evolution Hungary, s ',
				job: 'Saját irodaterület kialakítása, tervezés, koordináció és kivitelezési logisztika',
				description:
					'Budapest II. kerület Tölgyfa utca, irodaház tetőtéri bérlemény, saját iroda kialakítása. A Raiffeisen Evolution csapattagjaként saját irodaterületünk kiépítése. Belső igényfelmérés és tervezési irányelvek meghatározása után, az alap kiépítési tervek készítése. Belsőépítész Törőcsik Ferenc (Platinium Group), építészeti és kivitelezési koordináció.  1100 nm első osztályú irodaterület kialakítása A++ szinten.',
				imgCollection: [],
				details: true,
			},
			{
				id: crypto.randomUUID(),
				year: '2008',
				location: 'Budapest',
				name: 'MTV székház',
				job: 'bővítés, és új irodaház építés projektmenedzsment szervezet, junior projektmenedzser',
				description: '',
				imgCollection: [],
				details: false,
			},
		],
	},
];

export const AboutMe = [
	{
		title: 'Szakmai tapasztalatok',
		data: [
			{
				id: 'job01',
				year: '2014 - ',
				company:
					'Független, saját tulajdonú családi cég, Insomnia Production Kft.',
				position: 'építészet, kivitelezés, projekt menedzsment',
				location: 'Budapest',
			},
			{
				id: 'job02',
				year: '2012 - 2014',
				company: 'Neumann Projekt és Szervezési Kft.',
				position: 'projektvezető, projektigazgató',
				location: 'Budapest',
			},
			{
				id: 'job03',
				year: '2008',
				company: 'WALLIS Ingatlanfejlesztő Rt.',
				position: 'projektvezető',
				location: 'Budapest',
			},
			{
				id: 'job04',
				year: '2008 - 2012',
				company: 'RE Project Development Kft.',
				position: 'projektvezető',
				location: 'Budapest',
			},
			{
				id: 'job05',
				year: '2007 - 2008',
				company: 'PROMEGA Építész Iroda',
				position: 'önálló építész, tervező',
				location: 'Budapest',
			},
			{
				id: 'job06',
				year: '2006 - 2007',
				company: 'ZALA-ART Építész Iroda',
				position: 'önálló építésztervező',
				location: 'Zalaegerszeg',
			},
			{
				id: 'job07',
				year: '2006',
				company: 'GRAPHICS DESIGN Tervező Bt',
				position: 'saját tervező cég megalapítása',
				location: 'Budapest',
			},
			{
				id: 'job08',
				year: '2005',
				company: 'HONOR KFT',
				position: 'főépítés-vezető, előkészítő mérnök',
				location: 'Budapest',
			},
			{
				id: 'job09',
				year: '2004 - 2005',
				company: 'körmendi Polgármesteri Hivatal',
				position: 'Kiemelt Építéshatósági ügyintéző',
				location: 'Budapest',
			},
		],
	},
	{
		title: 'Tanulmányok',
		data: [
			{
				id: 'school01',
				year: '2003 - 2006',
				school: 'Pécsi Tudományegyetem',
				dep: 'Építész egyetemi szak - okleveles építész',
			},
			{
				id: 'school02',
				year: '2004',
				school: 'Savia Polytechnic, Koupio, Finnország',
				dep: 'Erasmus ösztöndíj',
			},
			{
				id: 'school03',
				year: '1999 - 2002',
				school: 'Pécsi Tudományegyetem',
				dep: 'Építőmérnök főiskolai szak, szerkezetépítő szakirány',
			},
			{
				id: 'school04',
				year: '1995 - 1999',
				school: 'Széchenyi István Építőipari Szakközépiskola, Zalaegerszeg ',
				dep: '',
			},
		],
		qualification: [
			{
				id: 'qual01',
				title: 'Képesítések',
				description: [
					<>
						<li>
							- Ingatlanforgalmi és értékbecslői OKJ szakképesítés és
							jogosultság
						</li>
						<li>- Építész tervezői jogosultság, kamarai tagság</li>
						<li>- Köztisztviselő jogi alapvizsga</li>
						<li>- FMV „A” képesítés és teljes körű kivitelezői jogosultság</li>
					</>,
				],
			},
		],
	},
];
