//COLORS
export const link = '#FA7300';
export const orange = '#FF9721';
export const white = '#EDF0F2';
export const gray = '#a0aeb5';
// export const gray = '#B1C3CC';
export const darkGray = '#37474F';
export const black = '#263238';

//CSS PROPS
// export const border = 'border-radius: 0.563rem';
export const border = 'border-radius: 6px';
export const padding = 'padding: 1rem';
export const borderShadow =
	'box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;';

//UNITS
export const h1 = 'font-size: 1.6rem; font-weight: 600; margin: 0';
export const h2 = 'font-size: 1.25rem; font-weight: 600;';

export const unit50 = '3rem';

//576px 768px 992px 1200px 1400px
