import styled from 'styled-components';
import * as style from './Var.style';

export const Wrapper = styled.div`
	display: grid;
	grid-template-rows: repeat(2, auto);
	grid-template-areas:
		'row-one'
		'row-two';
	padding: 0 1rem;
`;

export const Container = styled.div`
	grid-area: ${(props) => props.$gridArea};
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-areas: 'title content content';
	width: 100%;
`;

export const Header = styled.div`
	grid-area: title;
	justify-self: center;
	align-self: start;
	position: sticky;
	${style.padding};
	top: 70px;

	& h1 {
		display: flex;
		justify-content: center;
	}
`;

export const Body = styled.div`
	grid-area: content;
	${style.padding};
	padding-left: 0;
	padding-bottom: 0;

	.section {
		${style.borderShadow};
		${style.padding};
		${style.border};
		background-color: ${style.darkGray};
		.toggle {
			cursor: pointer;
		}

		.title {
			color: ${style.orange};
			text-transform: uppercase;
			position: relative;

			.faPlus {
				position: absolute;
				right: 0;
				transition: transform 0.2s ease-in;
			}
		}

		.subTitle {
			color: ${style.white};
			text-transform: capitalize;
			font-weight: 300;
		}

		.accordion {
			border-left: 2px solid ${style.orange};
			height: 0;
			overflow: hidden;
			transition: height 0.2s ease-out;
			.subTitleList {
				font-style: italic;
				font-weight: 300;
			}
		}
		.show {
			height: auto;
		}
	}

	.jobTitle:last-child {
		margin-bottom: 0;

		span {
			font-weight: 600;
			display: inline-block;
			margin-bottom: 5px;
		}
	}

	.refBox {
		position: relative;
		height: 400px;
		background-size: cover;
		background-position: center;
		${style.border};
		&::before {
			content: '';
			position: absolute;
			bottom: 0;
			width: 100%;
			height: 40%;
			background-color: ${style.black};
			opacity: 0.8;
			border-radius: 0 0 6px 6px;
		}
		& img {
			object-fit: cover;
			width: 100%;
		}

		& .refBoxTitle {
			position: absolute;
			bottom: 0;
			& .subTitle {
				color: ${style.orange};
			}
			& span {
				font-weight: 600;
			}
		}
	}
`;
